/**
 * 現在地の座標を監視する
 */
export async function watchMapCurrentPosition(listener) {
  return new Promise((resolve, reject) => {
    // Options
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    // 端末の位置が変化するたびに自動的に呼び出される
    const id = navigator.geolocation.watchPosition(success, error, options);
    // 座標取得成功
    function success(pos) {
      listener.set_current_location({
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
      });
      resolve(id);
    }
    // 座標取得失敗
    function error(err) {
      //console.log(err);
      reject();
    }
  });
}
