import axios from 'axios';

/**
 * 現在地の座標を監視する
 */
export async function getNearbyShopList(location) {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/current_position_list/' + location.lat + ',' + location.lng)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        alert('最寄りの焼肉屋さんの取得に失敗しました。');
        reject(err);
      });
  });
}

/**
 * 現在地の座標を監視する
 */
export function showShopList(data) {
  let list_midashi = document.querySelector('.shop-detail-count');
  if (data.count) {
    list_midashi.innerHTML =
      '現在地の近くに<span style="font-weight: bold;font-size:1.2em;">' +
      data.count +
      '</span>件見つかりました';
  } else {
    list_midashi.textContent = '近くに焼肉屋さんが見つかりません';
  }

  let list_shop = document.querySelector('#shop_list');
  list_shop.innerHTML = '';
  data.shops.forEach(function (shop, i) {
    let d = '/images/car.png';
    if (shop.distance < 1000) {
      d = '/images/bicycle.png';
    }
    if (shop.distance < 500) {
      d = '/images/walk.png';
    }
    list_shop.innerHTML += `<div class="card mb-1 list-card" data-name='${shop.name}' data-lat='${shop.lat}' data-lng='${shop.lng}'>
        <div class="card-body pl-3 py-2">
          <div>
            <img src="${d}" height="25px;">
            ${shop.name}(${shop.distance_disp})
          </div>
        </div>
      </div>`;
  });

  // イベント ショップリストタップ
  const shop_list = document.querySelectorAll('.list-card');
  shop_list.forEach((val, index) => {
    val.addEventListener('click', function (e) {
      location = '/shop/' + val.dataset.name;
    });
  });
}
