import axios from 'axios';

/**
 * localStorageを使い状態を保存する。
 */
export class States {
  constructor() {
    // 最終更新日時
    this._update_datetime = '';

    // ショップアイコン
    this._shop_icons = [];

    // エリア保存
    if (localStorage.getItem('areas') == null) {
      sessionStorage.setItem('areas', []);
      this._save_areas = [];
    } else {
      this._save_areas = JSON.parse(localStorage.getItem('areas'));
    }

    // ショップ保存
    if (localStorage.getItem('shops') == null) {
      sessionStorage.setItem('shops', []);
      this._save_shops = [];
    } else {
      this._save_shops = JSON.parse(localStorage.getItem('shops'));
    }
  }

  /**
   * 最終更新日の確認
   *
   * LocalStorageに保存した_juju_updateと比較し、違っていたらLocalStorageをクリアする
   *
   * @returns
   */
  async checkUpdateTime() {
    return new Promise((resolve, reject) => {
      axios
        .get('/update_date')
        .then((response) => {
          if (response.data.update_date != localStorage.getItem('updated')) {
            localStorage.clear();
            localStorage.setItem('updated', response.data.update_date);
            this._save_areas = [];
            this._save_shops = [];
          }
          resolve('success');
        })
        .catch((err) => {
          alert('更新データの取得が失敗しました。');
          reject('error');
        });
    });
  }

  /**
   * エリア情報の保存と新たに追加されたエリアを返す。
   *
   * @param {array} areas
   * @return {array} 追加されたエリアCDを
   */
  storeAreas(areas) {
    let target_area = [];
    // 保存済みのエリアに含まれないエリアCDを求める
    areas.forEach((v) => {
      if (this._save_areas.includes(v) == false) {
        target_area.push(v);
      }
    });

    if (target_area.length > 0) {
      this._save_areas = this._save_areas.concat(target_area);
      localStorage.setItem('areas', JSON.stringify(this._save_areas));
    }
    return target_area;
  }

  /**
   * エリア情報の取得
   *
   * @return {array} 登録されている全エリア
   */
  getAreas() {
    return localStorage.getItem('areas');
  }

  // 店舗情報の保存
  storeShops(shops) {
    let area_shops = [];
    shops.forEach((v) => {
      const name = 'A' + v.r;
      if (!area_shops[name]) {
        area_shops[name] = JSON.stringify(v);
      } else {
        area_shops[name] = area_shops[name] + ',' + JSON.stringify(v);
      }
    });
    Object.keys(area_shops).forEach(function (key) {
      let data = JSON.stringify(JSON.parse('[' + area_shops[key] + ']'));
      let name = key.replace('A', '');
      localStorage.setItem('shops_' + name, data);
    });
  }

  /**
   * 店舗情報の取得
   *
   * @param {*} area
   * @returns
   */
  getShops(area) {
    return localStorage.getItem('shops_' + area);
  }

  /**
   * アイコン情報を取得する
   *
   * @returns
   */
  async importShopIcons() {
    return new Promise((resolve, reject) => {
      axios
        .get('/company_list')
        .then((response) => {
          this._shop_icons = response.data.shops;
          resolve('success');
        })
        .catch((err) => {
          alert('アイコン情報の取得が失敗しました。');
          reject('error');
        });
    });
  }

  /**
   * アイコンファイルの設定
   *
   * @param {*} shop_id
   */
  getIcon(shop_id) {
    let icon = '';
    this._shop_icons.forEach((v) => {
      if (v.id == shop_id) {
        icon = v.marker;
      }
    });
    return icon;
  }
}
