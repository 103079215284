import { getNearbyShopList, showShopList } from './shop.js';

/**
 * LocationListener
 * 現在位置を監視し変更があったら店舗リストの更新
 */
export class CurrentLocationListener {
  constructor() {
    this._location = { lat: 0, lng: 0 };
  }

  /**
   * 現在位置ロケーションをセット
   * @param {{lat:*, lng:*}} newLocation
   */
  async set_location(newLocation) {
    // 位置情報が変更されたら発火する
    if (this._location.lat != newLocation.lat || this._location.lng != newLocation.lng) {
      this._location = newLocation;
      // ajaxで現在位置に近いショップを取得する
      // TODO: アクセスが多くなる可能性があるため5~10m以上移動したら取得するようにするか
      const shop_list = await getNearbyShopList(newLocation);
      // 最寄り店舗リストを表示する
      showShopList(shop_list);
    }
  }

  /**
   * 現在位置ロケーションを取得
   */
  get_location() {
    return this._location;
  }
}
