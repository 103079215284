export class MarkerObj {
  constructor() {
    this._markers = [];
  }

  append(value) {
    this._markers.push(value);
  }

  is_exists(value) {
    let latlng = value.lat + ',' + value.lat;
    let result = this._markers.indexOf(latlng);
    if (result < 0) {
      this.append(latlng);
      return false;
    }
    return true;
  }
}
