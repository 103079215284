import axios from 'axios';

export async function shopPageMap() {
  let name = document.querySelector('#name').value;
  let lat = document.querySelector('#lat').value;
  let lng = document.querySelector('#lng').value;
  let zoom = document.querySelector('#zoom').value;
  let marker = document.querySelector('#marker').value;
  let company = document.querySelector('#company').value;

  const response = await getCompanyShopList(company);
  const shops = response.shops;
  let latlng = lat + ',' + lng;

  if (marker) {
    marker = '/storage/' + marker;
  } else {
    marker = '/images/default.png';
  }

  // 現在地Marker
  var current = L.icon({
    iconUrl: marker,
    iconSize: [72, 72], // アイコンのサイズ
    iconAnchor: [36, 72], // マーカーの位置に対応するアイコンの位置
    popupAnchor: [0, -70], // ポップアップを開く基準
  });

  let map = L.map('mini-map');

  map.setView([lat, lng], zoom);

  //表示するタイルレイヤのURLとAttributionコントロールの記述を設定して、地図に追加する
  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution:
      'Map data &copy; <a target="_blank" href="http://openstreetmap.org/">OpenStreetMap</a>',
    maxZoom: 19,
    minZoom: 6,
  }).addTo(map);

  shops.forEach((v, i) => {
    let bubble = `
      <div class="card" style="border: none;">
        <div class="card-body p-0 m-0" >
          <div><a href="/shop/${v.name}">${v.name}</a> 詳細へ</div>
        </div>
      </div>`;
    L.marker([v.lat, v.lng], { icon: current }).addTo(map).bindPopup(bubble); //.openPopup();
  });

  const shop_select = document.querySelector('#shop-select');
  shop_select.onchange = (event) => {
    location = '/shop/' + shop_select.value;
  };

  // 事業者の店舗をすべて取得する。
  async function getCompanyShopList(company_id) {
    return new Promise((resolve, reject) => {
      axios
        .get('/company_shop_list/' + company_id)
        .then((response) => {
          const res = response.data.shops;
          resolve(response.data);
        })
        .catch((err) => {
          alert('データの取得が失敗しました。');
          reject('error');
        });
    });
  }
}
