import 'leaflet/dist/leaflet.css';

export class Map {
  constructor() {}
  async draw_map(listener, markers, location) {
    const MAP = L.map('map');
    MAP.setView([location.lat, location.lng], 14);

    //表示するタイルレイヤのURLとAttributionコントロールの記述を設定して、地図に追加する
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        'Map data &copy; <a target="_blank" href="http://openstreetmap.org/">OpenStreetMap</a>',
      maxZoom: 19,
      minZoom: 11,
    }).addTo(MAP);

    var osmGeocoder = new L.Control.OSMGeocoder({
      placeholder: '都道府県...',
      //collapsed: false /* Whether its collapsed or not */,
      //position: 'topright' /* The position of the control */,
      text: '検索' /* The text of the submit button */,
      //bounds: null,
    });

    MAP.addControl(osmGeocoder);

    // 地図上の現在位置をリスナーに渡す。
    listener.set_location(MAP, markers, location);

    // マップをスライドした
    MAP.on('moveend', function (e) {
      let pos = MAP.getCenter();
      let zoom = MAP.getZoom();
      let params = pos.lat + ',' + pos.lng;
      let state = '/map?g=' + params + '&z=' + zoom;
      history.pushState(null, null, state);

      let new_location = {
        lat: pos.lat,
        lng: pos.lng,
      };
      listener.set_location(MAP, markers, new_location);
    });
  }

  async getLocationShops(listener) {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/shop_map/' + params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          alert('マーカー情報の取得が失敗しました。');
          reject(err);
        });
    });
  }
}
