import axios from 'axios';
import { getNearbyShopList, showShopList } from './shop.js';

/**
 * LocationListener
 * 現在位置を監視し変更があったら店舗リストの更新
 */
export class MapLocationListener {
  // init
  constructor(current, states) {
    this._save_map_area = 0;
    this._current_location = { lat: 0, lng: 0 };
    this._MAP = {};
    this._current = current;
    this._states = states;
    this._show_areas = []; // 表示したエリアの記録
  }

  /**
   * ロケーションがセットされた
   * @param {*} newLocation
   */
  async set_location(MAP, markers, newLocation) {
    this._MAP = MAP;
    let add_area = [];
    // 中心点のエリアCDからその隣接するアリアを求める
    const areas = this._calc_area(newLocation);
    // エリアを保存し追加されたエリアを取得
    add_area = this._states.storeAreas(areas);
    // ショップ情報を取得
    if (add_area.length > 0) {
      await axios.get('/area_shop_list?areas=[' + add_area.join(',') + ']').then((response) => {
        if (response.data.shops.length > 0) {
          this._states.storeShops(response.data.shops);
        }
      });
    }

    // エリアの再表示を防ぐため一度表示したエリアは除外する。
    // 保存済みのエリアに含まれないエリアCDを求める
    let show_areas = [];
    areas.forEach((v) => {
      if (this._show_areas.includes(v) == false) {
        show_areas.push(v);
        this._show_areas.push(v);
      }
    });
    if (show_areas.length > 0) {
      this._get_shops(MAP, markers, show_areas, this._states);
    }
  }

  get_location() {
    return this._save_map_area;
  }
  /**
   *
   * 現在位置ロケーションをセット
   * @param {{lat:*, lng:*}} newLocation
   */
  async set_current_location(newLocation, current) {
    // 位置情報が変更されたら発火する
    if (
      this._current_location.lat != newLocation.lat ||
      this._current_location.lng != newLocation.lng
    ) {
      this._current_location = newLocation;
      // ajaxで現在位置に近いショップを取得する
      // TODO: アクセスが多くなる可能性があるため5~10m以上移動したら取得するようにするか
      const shop_list = await getNearbyShopList(newLocation);
      // 最寄り店舗リストを表示する
      showShopList(shop_list);

      // 現在位置にMyIcon表示をプロットする
      this._show_current_icon(this._MAP, this._current_location, this._current);
    }
  }

  /**
   * 現在位置ロケーションを取得
   */
  get_current_location() {
    return this._current_location;
  }

  _calc_area(latlng) {
    const lat = latlng.lat;
    const lng = latlng.lng;
    const areas = [];
    // 基準点
    const BASE_LAT = 25.65502;
    const BASE_LNG = 126.683013;
    // 10Kmあたりの座標値
    const BLOCK_LAT = 0.089831486;
    const BLOCK_LNG = 0.109664824;
    const B_COUNT = 185;
    // 算出
    const b_lat = lat - BASE_LAT;
    const b_lng = lng - BASE_LNG;
    const _y = Math.floor(b_lat / BLOCK_LAT);
    const _x = Math.floor(b_lng / BLOCK_LNG);
    const area = _y * B_COUNT + _x;
    // 算出
    areas.push(area + 185);
    areas.push(area + 186);
    areas.push(area + 187);
    areas.push(area - 1);
    areas.push(area);
    areas.push(area + 1);
    areas.push(area - 187);
    areas.push(area - 186);
    areas.push(area - 185);

    areas.push(area + 370);
    areas.push(area + 371);
    areas.push(area + 372);
    areas.push(area + 373);
    areas.push(area + 374);

    areas.push(area + 184);
    areas.push(area + 188);

    areas.push(area - 2);
    areas.push(area + 2);

    areas.push(area - 188);
    areas.push(area - 184);

    areas.push(area - 374);
    areas.push(area - 373);
    areas.push(area - 372);
    areas.push(area - 371);
    areas.push(area - 370);

    return areas;
  }

  // マップ移動によってマーカーを書き込む
  _get_shops(MAP, markers, area, stats) {
    // 店舗マーカー設定
    const SHOP_MARKER = {
      iconUrl: '',
      iconSize: [72, 72], // アイコンのサイズ
      iconAnchor: [36, 72], // マーカーの位置に対応するアイコンの位置
      popupAnchor: [0, -70], // ポップアップを開く基準

      // iconSize: [52, 72], // アイコンのサイズ
      // iconAnchor: [26, 72], // マーカーの位置に対応するアイコンの位置
      // popupAnchor: [0, -70], // ポップアップを開く基準
    };

    // マーカー作成
    const clusters = L.markerClusterGroup({
      disableClusteringAtZoom: 14, //クラスタ化を解除するズーム
      maxClusterRadius: 80, //クラスタ化の半径(初期値80)
    });

    area.forEach((v) => {
      const shops = JSON.parse(this._states.getShops(v));
      if (shops) {
        shops.forEach(function (shop, i) {
          const shop_icon = stats.getIcon(shop.i);
          if (shop_icon) {
            SHOP_MARKER.iconUrl = 'storage/' + shop_icon;
          } else {
            SHOP_MARKER.iconUrl = 'images/default.png';
          }

          let icon = L.icon(SHOP_MARKER);
          let bubble = `
                <div class="card" style="border: none;">
                  <div class="card-body p-0 m-0" >
                    <div><p class="fw-bold mb-2 mt-2">${shop.n}</p></div>
                    <div class="card-subtitle mb-2 text-muted"><i class="fa-solid fa-location-dot"></i>${shop.a}</div>
                    <div class="d-grid gap-2">
                      <a href="/shop/${shop.n}" class="btn  btn-primary btn-sm text-white">
                        <button class="btn btn-primary btn-sm" type="button">詳細ページへ</button>
                      </a>
                    </div>
                  </div>
                </div>`;
          let title = Object.assign({}, shop);
          let marker = L.marker(new L.LatLng(shop.t, shop.g), { icon: icon });
          marker.bindPopup(bubble);
          clusters.addLayer(marker);
        });
        MAP.addLayer(clusters);
      }
    });
  }

  // マップ移動によってマーカーを設置する
  _show_current_icon(MAP, location, current) {
    // 現在地マーカー設定
    const CURRENT_MARKER = {
      iconUrl: 'images/current.png',
      iconSize: [50, 80],
      iconAnchor: [25, 75],
      popupAnchor: [0, -70],
    };

    let current_location = [location.lat, location.lng];
    // マーカー設定
    if (current.cp != '') {
      MAP.removeLayer(current.cp);
      MAP.removeLayer(current.circle500);
      MAP.removeLayer(current.circle1000);
      MAP.removeLayer(current.circle3000);
      //MAP.removeLayer(current.circle10000);
    }
    let marker = L.icon(CURRENT_MARKER);
    current.cp = L.marker(current_location, { icon: marker }).addTo(MAP).bindPopup('現在地'); //.openPopup();
    // current.circle10000 = L.circle(current_location, {
    //   radius: 20000,
    //   color: '#0FF',
    //   fill: true,
    //   fillColor: '#0FF',
    //   fillOpacity: 0.2,
    //   weight: 2,
    //   clickable: false,
    // }).addTo(MAP);
    current.circle3000 = L.circle(current_location, {
      radius: 3000,
      color: '#0FF',
      fill: false,
      // fillColor: '#0FF',
      // fillOpacity: 0.2,
      weight: 2,
      clickable: false,
    }).addTo(MAP);
    current.circle1000 = L.circle(current_location, {
      radius: 1000,
      color: 'orange',
      fill: false,
      // fillColor: 'orange',
      // fillOpacity: 0,
      weight: 2,
      clickable: false,
    }).addTo(MAP);
    current.circle500 = L.circle(current_location, {
      radius: 500,
      color: 'red',
      fill: false,
      // fillColor: 'red',
      // fillOpacity: 0,
      weight: 2,
      clickable: false,
    }).addTo(MAP);
  }
}
